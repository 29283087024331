import DashboardLayoutVue from '@/layouts/dashboard.vue';
import { createRouter, createWebHistory, type RouteMeta } from 'vue-router';
interface IRouteMeta {
  title: string
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        title: 'Login',
      } as RouteMeta & IRouteMeta,
    },
    {
      path: '/',
      component: DashboardLayoutVue,
      redirect: '/home',
      meta: {
        title: 'Dashboard',
      },
      children: [
        {
          path: 'home',
          name: 'home',
          component: () => import('@/views/dashboard/examples/Home.vue'),
          meta: {
            title: 'Home',
          } as RouteMeta & IRouteMeta
        },
        {
          path: 'task',
          name: 'tasks_index',
          component: () => import('@/views/dashboard/examples/tasks/Index.vue'),
          meta: {
            title: 'Tasks',
          } as RouteMeta & IRouteMeta
        },
        {
          path: '/users',
          name: 'user_index',
          component: () => import('@/views/users/user-list.vue'),
          meta: {
            title: 'Users',
          } as RouteMeta & IRouteMeta,
          
        },
        {
          path: '/users/quizzes',
          name: 'quizzes_index',
          component: () => import('@/views/quizzes/Quizzes.vue'),
          meta: {
            title: 'users/quizzes',
          } as RouteMeta & IRouteMeta
        },
        {
          path: '/users/engagement-quota',
          name: 'engagement-quota_index',
          component: () => import('@/views/engQuota/quotaList.vue'),
          meta: {
            title: 'users/engagement-quota',
          } as RouteMeta & IRouteMeta
        },
        {
          path: '/users/detail/:id',
          name: 'user_detail',
          component: () => import('@/views/users/user-detail.vue'), 
          props: true, 
          meta: {
            title: 'User Detail',
          } as RouteMeta & IRouteMeta,
        },
       
        {
          path: 'settings',
          name: 'settings_index',
          component: () => import('@/views/dashboard/examples/settings/Index.vue'),
          meta: {
            title: 'Settings',
          } as RouteMeta & IRouteMeta
        },
        {
          path: '/plans',
          name: 'plans_index',
          component: () => import('@/views/plans/web-plan.vue'),
          meta: {
            title: 'Plans',
          } as RouteMeta & IRouteMeta
        },
        {
          path: '/plans/add',
          name: 'add_plan',
          component: () => import('@/views/plans/addPlan.vue'),
          meta: {
            title: 'Add Plan',
          } as RouteMeta & IRouteMeta
        },
        {
          path: '/plans/edit/:id',
          name: 'edit_plan',
          component: () => import('@/views/plans/addPlan.vue'),
          props: true,
          meta: {
            title: 'Edit Plan',
          } as RouteMeta & IRouteMeta
        },
        {
          path: '/languages',
          name: 'languages',
          component: () => import('@/views/language/list.vue'),
          meta: {
            title: 'Languages',
          } as RouteMeta & IRouteMeta
        },
        {
          path: '/countries',
          name: 'countries',
          component: () => import('@/views/country/country-list.vue'),
          meta: {
            title: 'Countries',
          } as RouteMeta & IRouteMeta
        },

      ],
    },

    {
      path: '/:pathMatch(.*)',
      name: 'not-found',
      component: () => import('@/views/404.vue'),
      meta: {
        title: 'Page Not Found',
      } as RouteMeta & IRouteMeta,
    },
  ]
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('BearerToken'); 

  if (!token && to.name !== 'login') {
    next({ name: 'login' });
  } else if (token && to.name === 'login') {
    next({ name: 'home' });
  } else {
    next();
  }
  document.title = to.meta.title as string;
  // document.title = loc.meta.title as string;
})

export default router
