import axiosInstance from "./axiosInstance";
import API_ENDPOINTS from "./apiRepository";

export const login = async (params:object) => await axiosInstance.post(API_ENDPOINTS.AUTH.LOGIN,params);
// export const login = (credentials: { email: string; password: string }) =>
//   axiosInstance.post(API_ENDPOINTS.AUTH.LOGIN, credentials);

export const veriFication =async (userData:{ id: any, verified_code: any }) => await
  axiosInstance.post(API_ENDPOINTS.AUTH.VERIFICATION, userData);
export const ResendVeriFication = async  (id:{id: any}) => await
  axiosInstance.post(API_ENDPOINTS.AUTH.RESEND, id);

export const logout = () => axiosInstance.post(API_ENDPOINTS.AUTH.LOGOUT);
