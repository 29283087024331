interface IDashboardMenus {
  title: string
  icon: string
  path: string
  description?: string
  hidden?: boolean
}

export const SIDEBAR_EXPAND_WIDTH = 280;
export const SIDEBAR_COLLAPSED_WIDTH = 72;
export const APP_MENU: Record<string, { name: string, routes: IDashboardMenus[] }> = {
  main: {
    name: 'Core',
    routes: [
      {
        title: 'Dashboard',
        icon: 'LayoutDashboard',
        path: '/home',
      },
      // {
      //   title: 'Tasks',
      //   icon: 'ClipboardCheck',
      //   path: '/task',
      //   description: 'Manage Tasks'
      // },
    ],
  },
  settings: {
    name: 'General',
    routes: [
      {
        path: '/users',
        title: 'Users',
        icon: 'Users',
        description: 'Manage Users'
      },
      {
        path: '/users/quizzes',
        title: 'Quizzes',
        icon: 'CheckSquare',
        description: 'Manage Quizzes'
      },
      {
        path: '/users/engagement-quota',
        title: 'Engagement',
        icon: 'Layers',
        description: 'Manage Engagement'
      },
    
      // {
      //   path: '/settings',
      //   title: 'Settings',
      //   icon: 'Settings2',
      //   description: 'Settings'
      // },
      {
        path: '/plans',
        title: 'Plans',
        icon: 'Send',
        description: 'Manage plans'
      },
      {
        path: '/languages',
        title: 'Languages',
        icon: 'Globe',
        description: 'Manage Languages'
      },
      {
        path: '/Countries',
        title: 'Countries',
        icon: 'DollarSign',
        description: 'Manage Languages'
      },
      
     
    ],
  },
};

export const globalSearch = {

};
