<script setup lang="ts">
import { ref, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import { Button } from '@/components/ui/button';
import Modal from './Modal.vue';

const emit = defineEmits(['click']);
const router = useRouter();
const authStore = useAuthStore();
const isLoading = ref(false);
const logOutModal = ref(null)

const openDialog = () => {
  logOutModal.value.openDialog()

};

const closeModal = () => {
  logOutModal.value.closeDialog()
};

const handleLogout = () => {
  try {
    isLoading.value = true;
    authStore.logoutUser();
    localStorage.removeItem("BearerToken");
    localStorage.removeItem("user");
    router.push('/login');
  } catch (e) {
    console.error("Some thing went wrong", e);
  } finally {
    isLoading.value = false;
    logOutModal.value.closeDialog()
  }

};
defineExpose({ openDialog });

</script>

<template>
  <div>
    <!-- Logout Button -->
    <!-- Confirmation Modal -->
    <Modal ref="logOutModal" :title="''" :content-class="' md:max-w-[416px] rounded-lg h-[316px]'"
      :title-class="'!p-0'">
      <template #trigger>
        <div class="hidden"></div>
      </template>

      <div class="flex flex-col justify-center items-center gap-2  sm:text-left p-5 pb-0">
        <div class="">
          <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="5" width="56" height="56" rx="28" fill="#FEE4E2" />
            <rect x="5" y="5" width="56" height="56" rx="28" stroke="#FEF3F2" stroke-width="10" />
            <path
              d="M33 28.3334V33M33 37.6667H33.0117M44.6667 33C44.6667 39.4434 39.4434 44.6667 33 44.6667C26.5567 44.6667 21.3334 39.4434 21.3334 33C21.3334 26.5567 26.5567 21.3334 33 21.3334C39.4434 21.3334 44.6667 26.5567 44.6667 33Z"
              stroke="#D92D20" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </div>
        <div class="text-secondary-accent text-[16px]  font-medium">
          Logout
        </div>
        <div class="text-accent-foreground/70 text-center text-sm font-normal ">
          Are you sure you want to logout?
        </div>
        <div class="flex justify-center w-full mt-3 gap-5">
          <Button :variant="'secondary'" class="!w-full" @click="closeModal()">Cancel</Button>
          <Button :variant="'destructive'" class="!w-full" :isLoading="isLoading"
            @click="handleLogout()">Logout</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
