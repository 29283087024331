import { defineStore } from "pinia";
import { login, veriFication, logout, ResendVeriFication} from "@/api/authApi";

export const useAuthStore = defineStore("auth", {

  actions: {

    async loginUser(credentials: { email: string; password: string, token: any }) {
      return new Promise(async (resolve, reject) => {
        try {
          const userDetail = {
            email: credentials.email,
            password: credentials.password,
            'g-recaptcha-response': credentials.token
          }
          const  response   = await login(userDetail);
          console.log('data',response );
          console.log(response.status,'data.status');
          if (response.status === 200 || String(response.status) === "success")  {
            resolve(response);
            console.log( resolve(response ),' resolve(response )');
          }
          else {
            reject(new Error("Invalid login attempt"));
          }
        } catch (error) {
          console.error("Login failed:", error);

          reject(new Error('Login failed'));

        }
      })
    },
    async userVerification(userDta:{ id: any, verified_code: any}) {
      console.log("userVerification");
      return new Promise(async (resolve, reject) => {
        try {
          const  data  = await veriFication(userDta);
          console.log(data,"verify");
          
          if (data.status === 200 || String(data.status) === "success") { 
            resolve(data);
          }
          else {
            reject(new Error("Some thing went wrong!"));
          }
        } catch (error) {
          console.error("Registration failed:", error);
          reject(new Error('Registration failed'));
        }
      })
    },
    async resendVerification(id:{id: any}) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await ResendVeriFication(id);
          if ((data.status === 200 || String(data.status) === "success")) { 
            resolve(data);
          }
          else {
            reject(new Error("Some thing went wrong!"));
          }
        } catch (error) {
          console.error("Resend veriFication failed:", error);
          reject(new Error('Resend veriFication failed'));

        }
      })
    },
    async logoutUser() {
      try {
        await logout();
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
  },
});
