import './assets/css/main.css';

import Icon from '@/components/ui/Icon.vue';
import Toaster from '@/components/ui/toast/Toaster.vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueFeather from 'vue-feather';
import { install } from "vue3-recaptcha-v2";
import App from './App.vue';
import PageHeaderVue from './components/ui/PageHeader.vue';
import router from './router';
const app = createApp(App)
app.component(VueFeather.name, VueFeather);
app.component('PageHeader', PageHeaderVue);
app.component('Toaster', Toaster);
app.component('Icon', Icon);
app.use(install, {
    sitekey: import.meta.env.VITE_APP_RECAPTCHA_SITEKEY,
    cnDomains: false, 
  })
app.use(createPinia())
app.use(router)
app.mount('#app')
