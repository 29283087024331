<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';

const props = defineProps({
  title: {
    type: String,
    default: 'Title',
  },
  contentClass: {
    type: String,
    default: '',
  },
  titleClass: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const open = ref(false);

const closeDialog = () => {
  open.value = false;
  emit('update:modelValue', open.value);
};

const openDialog = () => {
  open.value = true;
  emit('update:modelValue', open.value);
};
defineExpose({ openDialog,closeDialog });
</script>

<template>
  <Dialog v-model:open="open" @open="openDialog" @close="closeDialog" >
    <DialogTrigger as-child>
      <slot name="trigger"></slot>
    </DialogTrigger>
    <DialogContent  v-bind="$attrs"  @open-auto-focus.prevent class="sm:max-w-[425px] rounded" :class="contentClass">
      <div class="font-semibold ms-5 !mt-0 text-xl">{{ title }}</div>
      <DialogHeader class="p-6 !pt-0 pb-0" :class="titleClass">
        <DialogTitle></DialogTitle>
      </DialogHeader>
      <slot>
         <button @click="open=false"  ></button>
      </slot>

      <DialogFooter>
        <slot name="footer">
            
        </slot>
      </DialogFooter>
    
    </DialogContent>
  
  </Dialog>
</template>
