
const API_ENDPOINTS = {
    AUTH: {
      LOGIN: "admin/login",
      VERIFICATION: "verifyCode",
      RESEND: "resendVerifyCode",
      LOGOUT: "/auth/logout",
    },
    USERS: {
      LIST: "admin/users/list",
      DETAIL: (id: number) => `/users/detail/${id}`,
      EDIT: (id: number) => `/users/edit/${id}`,
      UPDATE: (id: number) => `/users/update/${id}`,
      PLANUPDATE: (id: number) => `/users/update-wix-plan/${id}`,
      WIXPRODUCTS: (id: number) => `/users/import-wix-products/${id}`,

      ADD:   "/users/save",
      DELETE: `/users/delete`,
    },

    PLANS: {
      LIST: "/web/plan/list",
      DETAIL: (id: number) => `web/plan/show/${id}`,
      STATUS: "/web/plan/change/status",
      STORE: "/web/plan/store",
      UPDATE: (id: number) => `/web/plan/update/${id}`,
      DELETE: (id: number) => `/plans/delete/${id}`,
    },
    QUIZZES: {
      LIST: "/quizzes/list",
      DETAIL: (id: number) => `/web/plan/${id}`,
      UPDATE: (id: number) => `/users/${id}/update`,
      DELETE: (id: number) => `/quizzes/delete/${id}`,
    },
    QUOTA: {
      LIST: "/engagement/settings/list",
      DETAIL: (id: number) => `engagement/edit/${id}`,
      UPDATE: (id: number) => `brands/update/${id}`,
      DELETE: (id: number) => `/engagements/delete/${id}`,
    },
    LANG: {
      LIST: "/language/list",
      DETAIL: (id: number) => `/language/edit/${id}`,
      CREATE: "/language/save",
      UPDATE: (id: number) => `language/update/${id}`,
      DELETE: (id: number) => `language/delete/${id}`,
    },
    CURRENCY: {
      LIST: "/countries/list",
      DETAIL: (id: number) => `/countries/edit/${id}`,
      CREATE: "/countries/save",
      UPDATE: (id: number) => `countries/update/${id}`,
      DELETE: (id: number) => `countries/delete/${id}`,
    },
    DASHBOARD: {
      LIST: "/loadDashboard",
      // DETAIL: (id: number) => `/countries/edit/${id}`,
      // CREATE: "/countries/save",
      // UPDATE: (id: number) => `countries/update/${id}`,
      // DELETE: (id: number) => `countries/delete/${id}`,
    },
  };
  
  export default API_ENDPOINTS;
  